.faq-container-alunos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5% 10%;
    background-color: #7E34F4;
}
.faq-container-alunos .faq-content-alunos {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
}
.faq-container-alunos .section-alunos h1 {
    margin-bottom: 4%;
    font-weight: lighter;
    font-size: xx-large;
    font-family: 'DM Mono', monospace;
    color: #CDFFA6;
}
.faq-container-alunos .section-alunos .details-alunos {
    border: 1px solid var(--card-grey);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 10px;
}
.faq-container-alunos .section-alunos .details-alunos .summary-alunos {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    color: #CDFFA6;
    cursor: pointer;
}
summary::marker {
    color: var(--border-btn);
}
.faq-container-alunos p {
    margin-top: 1rem;
    margin-left: 1.5rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1.5rem;
}