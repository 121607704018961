.contact-us {
    display: flex;
    justify-content: center;

    padding: 5% 10%;

    background-color: var(--card-pink);
}

.contact-us .contact-container {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
}
.contact-us .contact-container h1 {
    margin-top: -2rem;
    font-size: xx-large;
    font-weight: lighter;
    color: var(--border-timeline);
    font-family: 'DM Mono', monospace;
}

.contact-us .contact-container p {
    font-size: medium;
    line-height: 1.5rem;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.contact-us .contact-container button {
    padding: .7rem 3rem;

    font-size: small;
    font-weight: bolder;
    color: var(--border-timeline);
    font-family: 'Montserrat', sans-serif;

    border-radius: 0.125rem;
    background-color: var(--purple);
}

.contact-us .contact-container button:hover {
    color: var(--purple);

    cursor: pointer;
    transition-duration: .5s;
    background-color: var(--yellow-footer);
}

@media screen and (max-width: 610px) {
    .contact-us .contact-container h1 {
        margin-top: 0;
    }
}