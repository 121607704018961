.probiesInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 5% 10%;
    
    background-color: var(--card-purple);
}

.probiesInfo .probiesInfo-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.probiesInfo .probiesInfo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    text-align: center;
}

.probiesInfo .probiesInfo-container h1 {
    font-size: 46px;
    font-weight: bolder;
    color: var(--border-timeline);
    font-family: 'Montserrat', sans-serif;
}

.probiesInfo .probiesInfo-container p strong {
    font-family: 'Montserrat', sans-serif;
    color: var(--text-white);
}

.probiesInfo .probiesInfo-container p {
    margin: 1.5rem 0 2rem 0; 
    font-size: 16px;
    font-weight: 100;
    line-height: 1.5rem;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.probiesInfo .probiesInfo-container .probiesInfo-social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

.probiesInfo .probiesInfo-container h3 {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 14px;
    font-weight: 100;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
    text-decoration: underline;
}