.contact-us-alunos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
    padding: 3rem 6.5rem 4rem 6.5rem;
    background-color: #95EB57;
}

.contact-us-alunos h1 {
    font-family: 'DM Mono', monospace;
    font-size: 28px;
    font-weight: lighter;
    color: #7E34F4;
}

.contact-us-alunos p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5rem;
    color: #000000;
}

.contact-us-alunos button {
    background-color: #E00098;
    border-radius: 0.125rem;
    padding: .7rem 3rem;
    font-size: 12px;
    font-weight: bolder;
    color: var(--border-timeline);
    font-family: 'Montserrat', sans-serif;
}

.contact-us-alunos button:hover {
    background-color: var(--text-white);
    color: #E00098;
    transition-duration: .5s;
    cursor: pointer;
}