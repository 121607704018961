.hero-alunos {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    height: 100%;
    padding: 20% 10% 5% 10%;

    background-image: url(../../assets/hero-bg2.png);
    background-size: cover ;
}

.hero-alunos-container {
    display: block;
    align-items: flex-end;

    max-width: 1200px;
}

.hero-alunos-container h1 {
    font-size: 50px;
    width: 80%;
    color: var(--text-home);
    font-family: 'DM Mono', monospace;
    font-weight: lighter;
}

.hero-alunos-container h1 strong {
    color: var(--border-btn);
    font-family: 'DM Mono', monospace;
    font-weight: lighter;
}

.hero-alunos-container p {
    width: 60%;
    line-height: 25px;
    margin: 20px 0;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.hero-alunos-container button {
    background-color: var(--btn-home);
    border-radius: 0.125rem;
    padding: .7rem 3rem;
    font-size: 12px;
    font-weight: bolder;
    color: var(--card-pink);
    font-family: 'Montserrat', sans-serif;
}

.hero-alunos-container button:hover {
    background-color: var(--btn-hero-hover);
    transition-duration: .5s;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .hero-alunos-container h1 {
        font-size: 30px;
        width: 100%;
    }

    .hero-alunos-container p {
        padding-right: 2%;
        width: 100%;
    }
}