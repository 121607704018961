.trails {
    display: flex;
    justify-content: center;

    padding: 5% 10%;

    text-align: center;    
    color: var(--text-white);

    background-color: var(--btn-hero);    
}

.trails .trails-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.trails .trails-container .trails-title {
    font-size: 40px;
    font-weight: lighter;
    color: var(--btn-footer);
    font-family: 'DM Mono', monospace;
    margin-left: 1rem;
    text-align: left;
}

.trails .trails-container .trails-container {
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    margin-top: 3rem;
}

.trails .trails-container .trails-container .card-trail {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 140px;

    background-color: white;
}

.trails .trails-container .trails-container .card-trail img {
    height: 3rem;
}

.trails .trails-container .trails-container .card-trail h1 {
    font-weight: bolder;
    color: var(--card-purple);
    font-family: 'Montserrat', sans-serif;
}

.trails .trails-container .trails-container .card-trail p {
    font-weight: lighter;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 700px) {
    .trails .trails-container .trails-container {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        height: auto;
    }
    .trails .trails-container .card-trail {
        padding: 1.5rem;
    }

}

.trails .trails-container .second-line {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;
    margin-top: 2rem;
}

.trails .trails-container .second-line .card-trail {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 140px;

    background-color: white;
}

.trails .trails-container .second-line .card-trail img {
    height: 3rem;
}

.trails .trails-container .second-line .card-trail h1 {
    font-weight: bolder;
    color: var(--card-purple);
    font-family: 'Montserrat', sans-serif;
}

.trails .trails-container .second-line .card-trail p {
    font-weight: lighter;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 700px) {
    .trails .trails-container .second-line {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        height: auto;
        margin-top: 0.5rem;
        gap: 0.5rem;
    }
    .trails .trails-container .card-trail {
        padding: 1.5rem;
    }

}