.newClass-alunos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
    padding: 3rem 6.5rem 4rem 6.5rem;
    background-color: var(--card-pink);
}

.newClass-alunos h1 {
    font-family: 'DM Mono', monospace;
    font-size: 28px;
    font-weight: lighter;
    color: var(--text-hover);
}

.newClass-alunos button {
    background-color: var(--border-timeline);
    padding: 1rem 6rem;
    font-size: 12px;
    font-weight: bolder;
    color: var(--btn-hero);
    font-family: 'Montserrat', sans-serif;
}

.newClass-alunos button:hover {
    background-color: var(--text-white);
    color: var(--btn-hero);
    transition-duration: .5s;
    cursor: pointer;
}