.why-probies-alunos {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 900px;
    background-image: url(../../assets/why-probies-para-alunos-bg.png);
    background-size: cover;
}

.why-probies-alunos .why-probies-container h1 {
    font-family: 'DM Mono', monospace;
    font-size: 30px;
    font-weight: 500;
    margin-top: 3rem;
    color: var(--border-btn);
}

.why-probies-alunos img {
    margin-bottom: 1rem;
    height: 70px;
}

.why-probies-alunos p {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 1.5rem;
    color: var(--text-white);
}

.why-probies-alunos p strong {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}

.why-probies-alunos .card-price {
    margin-top: -34rem;
    margin-left: 50rem;
}

.why-probies-alunos .card-formation {
    margin-top: 1rem;
    margin-left: -26rem;
}

.why-probies-alunos .card-development {
    margin-top: 5rem;
    margin-left: -42rem;
}

.why-probies-alunos .card-script {
    margin-top: -24rem;
    margin-left: 26rem;
}

.why-probies-alunos .card-online {
    margin-top: 6rem;
    margin-left: 45rem;
}

.why-probies-alunos .card-transformation {
    margin-top: 5rem;
    margin-left: -52rem;
}

@media screen and (max-width: 1024px) {
    .why-probies-alunos {
        flex-wrap: wrap;
        height: auto;
        gap: 1rem;
        padding-bottom: 2rem;
    }
    .why-probies-alunos .why-probies-container {
        width: 100%;
    }
    .why-probies-alunos .card-transformation,
    .why-probies-alunos .card-formation,
    .why-probies-alunos .card-development,
    .why-probies-alunos .card-script,
    .why-probies-alunos .card-online,
    .why-probies-alunos .card-price {
        margin: 0;
    }
}