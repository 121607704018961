@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --purple: #6551bf;
  --text-color: #e4d9f4;
  --border-btn: #F99DDF;
  --btn-hover: #E35699; 
  --text-hover: #FFD578;
  --yellow-footer: #EDAF0C;
  --btn-footer: #DB9200;
  --text-home: #CDFFA6;
  --text-white: #FFFFFF;
  --btn-home: #C9F2A5; 
  --btn-hero: #5F22BF;
  --btn-hero-hover: #F19CDE;
  --card-purple: #6E0CF7; 
  --text-title: #4F1F9A;
  --border-timeline: #99F558;
  --black: #000000;
  --card-grey: #EAFFDB;
  --card-pink: #E00098;  
  --header-purple: #7E34F4;
  --arrow-puple: #AF007E;
  --title-trail: #CDFFA6;
  --trails-alunos: #846AF4;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}