.hero {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    height: 100%;
    padding: 20% 10% 7% 10%;

    background-image: url(../../assets/hero-empresas-bg2.png);
    background-size: cover;

}

.hero-container {
    display: block;
    align-items: flex-end;

    max-width: 1200px;
}

.hero-container h1 {
    padding-right: 10%;

    font-size: 50px;
    color: var(--text-home);
    font-weight: lighter;
    font-family: 'DM Mono', monospace;
}

.hero-container h1 strong {
    color: var(--border-btn);
    font-weight: lighter;
    font-family: 'DM Mono', monospace;
}

.hero-container p {
    padding: 2% 40% 2% 0;

    line-height: 25px;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.hero-container button {
    padding: .7rem 3rem;

    font-size: 12px;
    font-weight: bolder;
    color: var(--btn-hero);
    font-family: 'Montserrat', sans-serif;

    border-radius: 0.125rem;
    background-color: var(--btn-home);
}

.hero-container button:hover {
    cursor: pointer;
    transition-duration: .5s;
    background-color: var(--btn-hero-hover);
}

@media screen and (max-width: 767px) {
    .hero-container h1 {
        font-size: 40px;
        margin-top: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .hero-container h1 {
        font-size: 30px;
    }

    .hero-container p {
        padding-right: 2%;
    }
}