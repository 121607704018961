.footer-alunos { 
  z-index: 10;
  display: flex;
  justify-content: center;

  width: 100%;
  position: fixed;
  padding: 0 10%;
  bottom: 0;

  background-color: var(--card-pink);
}

.footer-alunos-content {
    display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1200px;
}

.scrollToTopButton-alunos {
  padding: 0 1rem;
  border: none;
  background-color: var(--arrow-puple);
  cursor: pointer;
}

.arrow-white {
  transform: scale(.5);
  filter: invert(100%);
}