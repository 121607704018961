footer {
  z-index: 10;
  display: flex;
  justify-content: center;

  width: 100%;
  position: fixed;
  padding: 0 10%;
  bottom: 0;

  background-color: var(--yellow-footer);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1200px;
}

.scrollToTopButton {
  padding: 0 1rem;
  cursor: pointer;

  border: none;
  background-color: var(--btn-footer);
}

.arrow-white {
  transform: scale(.5);
  filter: invert(100%);
}