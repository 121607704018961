.modalContainer {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    backdrop-filter: blur(4px);
}

.modal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    width: 442px;
    height: 650px;
    border-radius: 20px;
    padding: 15px;
    background-color: var(--text-white);
}

.modal h3 {
    width: 80%;
    text-align: center;
    font-family: 'Montserrat', Bold;
    font-size: 14px;
    color: var(--btn-hero);
}

.closeButtonContainer {
    width: 100%;
}

.closeButton {
    float: right;
    cursor: pointer;
}

.fieldContainer {
    display: flex;
    width: 100%;
    border: 1px solid #7e34f4;
    height: 45px;
    margin-top: 10px;
}

.fieldContainer.invalid {
    border: 1px solid red;
}

.rowContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;
    height: 65px;
    width: 320px;
}

.fieldContainer input {
    outline: none;
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin: 10px;
    width: 85%;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7e34f4;
    width: 15%;
}

.sendButton {
    width: 100%;
    height: 50px;
    font-size: 15px;
    margin-top: .5rem;
    background-color: #7e34f4;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    cursor: pointer;
}

.sendButton.success {
    background-color: green;
}

.errorMessage {
    width: 100%;
    font-size: 12px;
    color: red;
    margin-top: 0.3rem;
    padding-left: 1rem;
}

label {
    color: #5F22BF;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
}

select {
    border: none;
    background: none;
    width: 82%;
    padding: 5px;
    color: var(--black);
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

option {
    color: var(--black);
}

option:disabled {
    color: #999;
}

select:required:invalid {
    color: #777575;
}

select:focus {
    outline: none;
}
