.faq-container-empresas {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 5% 10%;

    background-color: var(--btn-hero);    
}

.faq-container-empresas .faq-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.faq-container-empresas .section-empresas {
    width: 100%;
    max-width: 1200px;
}

.faq-container-empresas section h1 {
    margin-top: 60px;
    margin-bottom: 30px;
    font-weight: lighter;
    font-size: xx-large;
    font-family: 'DM Mono', monospace;
    color: #CDFFA6;
}

.faq-container-empresas section details {
    border: 1px solid var(--card-grey);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 10px;
}

.faq-container-empresas section .details-empresas {
    border: 1px solid var(--card-grey);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 20px;
}

.faq-container-empresas section details .summary-empresas {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #CDFFA6;
    cursor: pointer;
}

summary::marker {
    color: var(--border-btn);
  }

.faq-container-empresas .paragraph-empresas {
    margin-top: 1rem;
    margin-left: 1.5rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1.7rem;
}
