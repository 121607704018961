.trails-alunos {
    display: flex;
    justify-content: center;
    padding: 5% 10%;
    text-align: center;
    color: var(--text-white);
    background-color: #7E34F4;
}
.trails-alunos .trails-alunos-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
}
.trails-alunos .text-container-alunos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    text-align: center;
}
.trails-alunos .text-container-alunos h1 {
    font-family: 'DM Mono', monospace;
    font-size: 38px;
    font-weight: lighter;
    color: var(--title-trail);
}
.trails-alunos .text-container-alunos h1 strong {
    font-family: 'DM Mono', monospace;
    color: var(--border-btn);
}
.trails-alunos .text-container-alunos p {
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
    margin: 1.5rem 0 1rem 0;
    line-height: 1.8rem;
    font-size: 14px;
    font-weight:lighter;
}
.trails-alunos .text-container-alunos p strong {
    font-family: 'Montserrat', sans-serif;
}
.trails-alunos .trails-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    background-color: #6B55C6;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    width: fit-content;
}
.trails-alunos .trails-container-alunos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 13rem;
    width: 100%;
    margin-top: 3rem;
}
.trails-alunos .trails-container-alunos .card-trail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    height: 100%;
}
.trails-alunos .trails-container .card-trail img {
    height: 3rem;
}
.trails-alunos .trails-container-alunos .card-trail h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
    color: var(--card-purple);
}
.trails-alunos .trails-container-alunos .card-trail p {
    font-family: 'Montserrat', sans-serif;
    font-weight: lighter;
    color: var(--black);
}

@media screen and (max-width: 660px) {
    .trails-alunos .trails-container-alunos {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
    }

    .trails-alunos .trails-container-alunos .card-trail {
        padding: 1.5rem;
    }
}