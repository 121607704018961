.why {
    display: flex;
    justify-content: center;

    padding: 5% 10%;

    color: var(--text-white);

    background-color: var(--btn-hero);
}

.why .why-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.why .why-content .why-title {
    margin-bottom: 5rem;

    font-size: 51px;
    font-weight: lighter;
    color: var(--border-timeline);
    font-family: 'DM Mono', monospace;
}

.why .why-content .why-container {
    gap: 2.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.why .why-content .card-why {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2rem;

    text-align: center;

    background-color: var(--text-white);
}

.why .why-content .card-why p {
    font-size: large;
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;
}

.why .why-content .card-why:nth-child(1) {
    background-color: var(--card-grey);
    color: var(--card-purple);
}

.why .why-content .card-why:nth-child(2) {
    background-color: var(--border-btn);
    color: var(--card-pink);
}

.why .why-content .card-why:nth-child(3) {
    background-color: var(--border-timeline);
    color: var(--card-purple);
}

.why .why-content .card-why:nth-child(4) {
    background-color: var(--card-purple);
    color: var(--border-timeline);
}

.why .why-content .card-why:nth-child(5) {
    background-color: var(--text-white);
    color: var(--card-purple);
}

.why .why-content .card-why:nth-child(6) {
    background-color: var(--card-purple);
    color: var(--border-timeline);
}

.why .why-content .card-why:nth-child(7) {
    background-color: var(--border-timeline);
    color: var(--card-purple);
}

.why .why-content .card-why:nth-child(8) {
    background-color: var(--border-btn);
    color: var(--card-pink);
}

.why .why-content .card-why:nth-child(9) {
    background-color: var(--card-grey);
    color: var(--card-purple); 
}

@media screen and (max-width: 980px) {
    .why .why-content .why-container {
        grid-template-columns: repeat(2, 1fr)
    }
}

@media screen and (max-width: 670px) {
    .why .why-content .why-container {
        grid-template-columns: repeat(1, 1fr)
    }
}