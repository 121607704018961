.student-timeline {
    display: flex;
    justify-content: center;
   
    padding: 0% 10%;
    
    text-align: center;
    color: var(--text-white);
    background-color: var(--btn-hero);
}

.student-timeline .student-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.student-timeline .student-container .timeline-title {
    padding: .5rem 1rem;

    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;

    border-radius: 1.5rem;
    background-color: var(--text-title);
}

.student-timeline .student-container .timeline-container img {
    width: 100%;

    object-fit: contain;
}

.student-timeline .student-container .timeline-container .mobile-image {
    display: none;
}

@media screen and (max-width:767px) {
    .student-timeline .student-container .timeline-container .mobile-image {
        display: block;
    }
    
    .student-timeline .student-container .timeline-container .desktop-image {
        display: none;
    }
}