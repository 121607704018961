.ilegraInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 5% 10%;
    
    background-color: var(--card-purple);
}

.ilegraInfo .ilegraInfo-content {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    align-items: center;

    width: 100%;
    max-width: 1200px;
}

.ilegraInfo .ilegraInfo-content .ilegraInfo-container h1 {
    font-size: 46px;
    font-weight: bolder;
    color: var(--border-timeline);
    font-family: 'Montserrat', sans-serif;
}

.ilegraInfo .ilegraInfo-content .ilegraInfo-container strong {
    color: var(--text-white);
}

.ilegraInfo .ilegraInfo-content .ilegraInfo-container p {
    margin: 2rem 0;

    line-height: 1.5rem;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.ilegraInfo .ilegraInfo-content .ilegraInfo-container h2  {
    margin-top: -2rem;
    margin-bottom: 1rem;

    font-size: 32px;
    font-weight: bolder;
   
    font-family: 'Montserrat', sans-serif;
}

a {
    color: var(--border-btn);
}

.ilegraInfo .ilegraInfo-content .ilegraInfo-container .social-links {
    gap: 1rem;
    display: flex;

    cursor: pointer;
}

.ilegraInfo .ilegraInfo-content .gallery-container {
    gap: .5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.ilegraInfo .ilegraInfo-content .gallery-container img {
    max-width: 100%;
}