.whoWeAre {
    display: flex;
    justify-content: center;

    padding: 0% 10% 5% 10%;
    
    color: var(--black);
    background-color: var(--btn-hero);
}

.whoWeAre .whoWeAre-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
}

.whoWeAre .whoWeAre-content img {
    z-index: 2;

    margin-right: -30%;
    margin-bottom: -10%;

    max-width: 80vw;
}

.whoWeAre .whoWeAre-content .whoWeAre-container {
    gap: 2rem;
    display: flex;
    flex-direction: column;

    padding: 3rem;

    background-color: var(--text-white);
}

.whoWeAre .whoWeAre-content .whoWeAre-container h1 {
    font-size: 46px;
    font-weight: lighter;
    color: var(--purple);
    font-family: 'DM Mono', monospace;
}

.whoWeAre .whoWeAre-content .whoWeAre-container p {
    font-size: medium;
    line-height: 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.whoWeAre .whoWeAre-content .whoWeAre-container p strong {
    color: var(--card-pink);
    font-family: 'Montserrat', sans-serif;
}

.whoWeAre .whoWeAre-content .whoWeAre-container button {
    width: 20rem;
    margin: auto;
    padding: 1rem 3rem;
    
    font-size: small;
    font-weight: bolder;
    color: var(--card-pink);
    font-family: 'Montserrat', sans-serif;

    border-radius: 0.125rem;
    background-color: var(--border-timeline);
}

.whoWeAre .whoWeAre-content .whoWeAre-container button:hover {
    color: var(--border-timeline);
    
    cursor: pointer;
    transition-duration: .5s;
    background-color: var(--arrow-puple);
}

@media screen and (max-width: 767px) {
    .whoWeAre .whoWeAre-content img {
        margin-right: 0;
    }
    .logo-container {
        margin: 0 auto;
    }
}