.method{
    display: flex;
    justify-content: center;

    height: 100%;
    padding: 2% 10% 10% 10%;
    
    background-size: cover;
}

.method .method-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    max-width: 1200px;
}

.method .method-container .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    text-align: center;
}

.method .method-container .text-container h1 {
    font-size: 32px;
    font-weight: lighter;
    color: var(--text-white);
    font-family: 'DM Mono', monospace;
}

.method .method-container .text-container h1 strong {
    color: var(--border-btn);
    font-family: 'DM Mono', monospace;
}

.method .method-container .text-container p {
    width: 65%;
    margin-top: 1rem;

    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;
}

.method .method-container .description-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-top: 25%;
}

.method .method-container .description-container .aceleration-program {
    margin-right: 3.5rem;

    color: var(--text-white);
}

.method .method-container .description-container .aceleration-program h1 {
    width: fit-content;
    padding: .5rem 1rem;
    margin-left: -1rem;

    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;

    border-radius: 1.5rem;
    background-color: var(--purple);
}

.method .method-container .description-container .aceleration-program p {
    margin-top: 1rem;

    font-size: 16px;
    line-height: 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

.method .method-container .description-container .interactive-card {
    color: var(--text-white);
}

.method .method-container .description-container .interactive-card .card {
    display: flex;
    padding: 2rem 2rem 3rem 2rem;

    cursor: pointer;
    border-radius: 15px;
}

.method .method-container .description-container .interactive-card .card img {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
}

.method .method-container .description-container .interactive-card .card h1 {
    margin-top: .6rem;

    cursor: pointer;
}

.method .method-container .description-container .interactive-card .card h1 {
    margin-top: .6rem;

    cursor: pointer;
}

.method .method-container .description-container .interactive-card .card p {
    margin-top: 1rem;
}

.method .method-container .description-container .interactive-card .probies {
    z-index: 1;

    background-color: var(--yellow-footer);
}

.method .method-container .description-container .interactive-card .upskilling {
    z-index: 2;
    margin-top: -1.5rem;

    background-color: var(--card-purple);  
}

.method .method-container .description-container .interactive-card .reskilling {
    z-index: 3;
    margin-top: -1.5rem;

    background-color: var(--btn-hover);
}

.method .method-container .description-container .interactive-card .diversity {
    z-index: 4;
    margin-top: -1.5rem;

    padding-bottom: 2rem;
    background-color: var(--btn-hero-hover);  
}

.method .method-container span {
    margin-top: 2.5rem;

    font-size: 16px;
    color: var(--text-white);
    font-family: 'Montserrat', sans-serif;

}

@media screen and (max-width: 1023px) {
    .method .method-container .description-container {
        display: flex;
        flex-wrap: wrap;
    }

    .method .method-container .description-container .aceleration-program {
        margin-right: 0;
        margin-bottom: 2rem;
    }
}