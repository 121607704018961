header {
  z-index: 10;
  display: flex;
  justify-content: center;

  width: 100%;
  position: fixed;
  padding: 0 10%;

  background-color: var(--purple);
  font-family: 'Montserrat', sans-serif;
}

.header-content {
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: 1200px;
  padding: 1.4rem 0;
}

.logo-container img {
  cursor: pointer;
}

nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 2.5rem;
  align-items: center;
}

.label-1 {
  width: 63px;
}

.label-2 {
  width: 61px;
}

.label-3 {
  width: 94px;
}

.label-4 {
  width: 96px;
}

nav a {
  color: var(--text-color);
  font-size: smaller;
  cursor: pointer;
}

nav a:hover {
  color: var(--text-hover);
  transition-duration: .5s;
  font-size: smaller;
  font-weight: bold;
}

.nav-btn {
  border: 1px solid var(--border-btn);
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 20px;
  cursor: pointer;
  color: var(--border-btn);
}

.nav-btn:hover {
  background-color: var(--btn-hover);
  color: var(--text-white);
  transition-duration: .5s;
}
@media screen and (max-width: 980px) {
  header nav {
    display: none;
  }
}