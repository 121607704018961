.header-alunos {
  z-index: 10;
  display: flex;
  justify-content: center;

  width: 100%;
  position: fixed;
  padding: 0 10%;

  background-color: var(--header-purple);
  font-family: 'Montserrat', sans-serif;
}

.header-alunos-content {
  display: flex;
  justify-content: space-between;
  
  width: 100%;
  max-width: 1200px;
  padding: 1.4rem 0;
}

.logo-container-alunos img {
  cursor: pointer;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 2.5rem;
  align-items: center;
}

.label-01 {
  width: 75px;
}

.label-02 {
  width: 94px;
}

.label-03 {
  width: 25px;
}

.label-04 {
  width: 96px;
  white-space: nowrap;
}

nav a {
  color: var(--text-color);
  font-size: smaller;
  cursor: pointer;
}

nav a:hover {
  color: var(--text-hover);
  transition-duration: .5s;
  font-size: smaller;
  font-weight: bold;
}

.nav-btn-alunos {
  border: 1px solid var(--text-home);
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: var(--text-home);
}

.nav-btn-alunos:hover {
  background-color: var(--card-pink);
  border: 1px solid var(--card-pink);
  color: var(--text-home);
  transition-duration: .5s;
}